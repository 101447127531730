.align-button-selected path {
    fill: black;
}

.align-button path {
    fill: #111013;
    fill-opacity: 0.4;
}

svg.question-icon {
    padding-left: 4px;
    padding-top: 7px;
    font-size: 19px;
    margin: auto;
}

svg.lock-icon {
    font-size: 16px
}

.image-dims-align-grid {
    padding-bottom: 16px;
}

.image-dims-align-grid-item {
    min-width: 350px;
}

.alt-text-container {
    padding-bottom: 16px;
}

.link-container {
    padding-bottom: 16px;
}

.img-dims-input {
    padding-right: 8px;
    border-radius: 0px;
}

.layout-tab-container {
    padding: 16px;
}

label.field-label {
    padding-bottom: 6px;
}