/*TODO: Cleanup unused CSS and break this file down*/
@import url(https://fonts.googleapis.com/css?family=Spectral:200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic);

:root {
  --cortex-primary: #1e78ff;
  --cortex-primary-dark: #196eee;
  --cortex-secondary: #0f0e0d;
  --cortex-dark-header: #111013;
  --cortex-grey: #f1f1f2;
  --cortex-light-grey: #f4f4f4;
  --cortex-empty: #636078;
  --cortex-white: #ffffff;
  --cortex-red: #fd243e;
  --cortext-error: #ff6060;
  --cortex-bg: #ffffff;
  --cortex-google: #1976d2;
  --cortex-microsoft: #00a4df;
  --cortex-field-border: 1px solid #e2e2e2;
  --cortex-field-bgColor: #fafafa;
  --cortex-placeholders: #9f9f9e;
  --cortex-field-radius: 8px;
  --cortex-grid: 1440px;
  --cortex-header: 48px;
  --cortex-bottom-bar: 40px;
  --tf-font: "SuisseIntl", sans-serif;
  --tf-generated-content-font: "Roboto";
  --tf-font-color: #0a0a0a;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-webkit-input-placeholder {
  color: var(--cortex-placeholders);
}

:-ms-input-placeholder {
  color: var(--cortex-placeholders);
}

::placeholder {
  color: var(--cortex-placeholders);
}

::selection {
  background-color: #f0f0f0 !important;
}

a {
  color: var(--cortex-primary);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

ul {
  padding: 0;
  margin: 0;
}

body,
html {
  font-family: var(--tf-font);
  font-weight: 400;
  color: var(--cortex-black);
  letter-spacing: normal;
  background-color: var(--cortex-white);
  letter-spacing: normal;
}

header,
main,
footer {
  width: 100%;
  height: auto;
  display: block;
}

.container-flex {
  display: flex;
}

.cortex-grid {
  width: 100%;
  height: auto;
  /* max-width: var(--cortex-grid); */
  margin: 0 auto;
}

.cortex-grid-sm {
  max-width: 960px;
}

.cortex-grid-md {
  max-width: 1040px;
}

header {
  width: 100%;
  min-height: var(--cortex-header);
  height: auto;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 14px;
  padding-right: 8px;
  z-index: 101;
  background-color: var(--cortex-white);
  /* box-shadow: 0px 1px 0px #e2e2e2; */
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

header.scroll-active {
  z-index: 101;
}

header .header-inner {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header .header-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

header .header-inner .logo {
  width: 159px;
}

header .header-inner .search-container {
  width: 100%;
  height: 44px;
  max-width: 414px;
  background-color: var(--cortex-field-bgColor);
  border: var(--cortex-field-border);
  border-radius: var(--cortex-field-radius);
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

header .header-inner .search-container input,
input.cortex-input-dark {
  width: 100%;
  font: normal normal 500 14px/19px var(--tf-font);
  border: none;
  outline: none;
  background-color: transparent;
}

input.cortex-input-dark {
  color: white;
}

.cortex-tab-color {
  color: #ffffff !important;
}

input.cortex-input-dark::selection {
  color: #000000; /* Selection is white so make text black */
}

header .header-inner .header-right {
  width: fit-content;
  height: 48px;
  display: flex;
  align-items: center;
}

header .header-inner .profile-container {
  width: 150px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.right-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

header .header-inner .profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--cortex-light-grey);
  overflow: hidden;
}

header .header-inner .profile-pic img {
  width: 100%;
  height: auto;
}

header .header-inner .profile-name {
  width: 100%;
  max-width: 80px;
  font: normal normal 800 16px/22px var(--tf-font);
  color: var(--cortex-secondary);
  padding-right: 10px;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: "pointer";
}

header .header-inner .notification {
  width: 40px;
  height: 40px;
}

.profile-name::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 6px;
  top: 50%;
  right: 0;
  z-index: 1;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: center center;
  cursor: "pointer";
}

header.dark {
  background-color: var(--cortex-dark-header);
  position: "sticky";
  top: 0;
  width: 100%;
  z-index: 2000;
}

header.dark .search-container {
  background-color: #454348;
  border: none;
  border-radius: unset;
}

header.dark ~ div.no-header > main {
  margin-top: 48px;
  min-height: calc(100vh - 48px);
}

.no-margin-top {
  margin-top: 0 !important;
  min-height: 100vh !important;
}

header.dark .header-inner .search-container {
  height: 36px;
}

header.dark .header-inner .search-container input {
  color: var(--cortex-white);
  background-color: #454348;
}

main {
  width: 100%;
  height: auto;
  min-height: calc(100vh - var(--cortex-header));
  /* margin-top: var(--cortex-header); */
  background-color: var(--cortex-grey);
}

.cortex-text {
  line-height: 19px;
  letter-spacing: -0.02em;
}

.d-none {
  display: none;
}

.login-box .login-box-title {
  font: normal normal 700 28px/38px var(--tf-font);
  color: var(--cortex-secondary);
}

.login-box .login-welcome-text {
  font: normal normal 500 20px/27px var(--tf-font);
  color: var(--cortex-placeholders);
  margin-top: 8px;
}

.login-box .login-welcome-text span {
  color: var(--cortex-secondary);
}

.social-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0;
}

.login-or {
  width: 100%;
  height: 24px;
  position: relative;
}

.login-or::before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  border-bottom: var(--cortex-field-border);
}

.login-or::after {
  content: "OR";
  width: fit-content;
  height: 24px;
  padding: 0 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  font: normal normal 500 16px/22px var(--tf-font);
  color: #6f6e6e;
  transform: translateX(-50%);
  z-index: 2;
  background-color: var(--cortex-white);
}

.login-form-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-top: 24px;
}

.login-footer {
  width: 100%;
  height: auto;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  font: normal normal 500 16px/22px var(--tf-font);
  color: var(--cortex-secondary);
}

.login-footer a {
  color: var(--cortex-primary);
  text-decoration: none;
}

.login-footer a:hover {
  color: var(--cortex-primary-dark);
  text-decoration: none;
}

.login-otp-container {
  width: 100%;
  height: auto;
  margin: 60px 0 40px;
}

.login-otp-container .otp-row {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.login-otp-container .otp-row input {
  width: 100%;
  height: 85px;
  border: var(--cortex-field-border);
  border-radius: var(--cortex-field-radius);
  background-color: var(--cortex-grey);
  text-align: center;
  font: normal normal 700 28px/38px var(--tf-font);
  color: var(--cortex-secondary);
  outline: none;
  box-shadow: none;
}

.login-otp-container .otp-counter {
  display: block;
  margin-top: 16px;
  font: normal normal 500 16px/22px var(--tf-font);
  color: var(--cortex-placeholders);
}

.login-otp-container .otp-counter span {
  color: var(--cortex-secondary);
}

.login-progress {
  width: 100%;
  height: 4px;
  background-color: #f3f3f1;
  border-radius: 8px;
  margin-bottom: 24px;
  position: relative;
}

.login-progress .progress-bar {
  width: 05;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #ff6060;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

/* Form Elements */

.MuiButton-startIcon {
  margin-right: 20px !important;
}

button.cortex-btn {
  background-color: unset;
  border-radius: 0px;
  border: none;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 21px;
  cursor: pointer;
}

button.cortex-btn-dark {
  background-color: #454348;
  color: #c2bdc8;
  min-height: 36px;
}

.cortex-form-row {
  width: 100%;
  height: auto;
}

.cortex-form-row label {
  display: block;
  font: normal normal 700 16px/22px var(--tf-font);
  color: var(--cortex-secondary);
  margin-bottom: 8px;
}

.cortex-form-row input:not([type="checkbox"]) {
  width: 100%;
  height: 44px;
  display: block;
  font: normal normal 500 16px/22px var(--tf-font);
  color: var(--cortex-secondary);
  padding: 0 16px;
  outline: none;
  box-shadow: none;
  position: relative;
  background-color: var(--cortex-field-bgColor);
  border: var(--cortex-field-border);
  border-radius: var(--cortex-field-radius);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.cortex-form-row input:focus,
.cortex-form-row input:active,
.cortex-form-row select:focus,
.cortex-form-row select:active {
  border: var(--cortex-field-border);
}

.cortex-form-row textarea {
  width: 100%;
  height: 150px;
  display: block;
  font: normal normal 500 14px/19px var(--tf-font);
  color: var(--cortex-secondary);
  padding: 12px 16px;
  outline: none;
  box-shadow: none;
  position: relative;
  border: var(--cortex-field-border);
  border-radius: var(--cortex-field-radius);
  resize: none;
}

/* Color & Bgs */

.bgs-grey {
  background-color: var(--cortex-light-grey);
}

/* Dashboard */

.dashboard-header-container {
  width: 100%;
  height: auto;
  padding: 24px 24px 0;
  background-color: var(--cortex-white);
  color: var(--cortex-secondary);
}

.dashboard-header-container .dashboard-header-title {
  font: normal normal 700 20px/27px var(--tf-font);
  margin-bottom: 8px;
}

.dashboard-header-container .dashboard-header-subTitle {
  font: normal normal 400 14px/21px var(--tf-font);
}

.dashboard-header-container .dashboard-header-cards {
  width: 100%;
  max-width: 1180px;
  height: auto;
  margin: 0 auto;
  padding: 20px 0 40px;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 16px;
  cursor: pointer;
}

.dashboard-header-container .dashboard-header-cards {
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  padding: 20px 0 40px;
}

.dashboard-header-container .dashboard-header-cards .header-card .header-card-title {
  font: normal normal 700 14px/21px var(--tf-font);
  margin: 12px 0 4px;
}

.dashboard-header-container .dashboard-header-cards .header-card .header-card-desc {
  font: normal normal 400 12px/16px var(--tf-font);
  color: var(--cortex-empty);
}

.dashboard-header-container .dashboard-header-tab {
  width: 100%;
  max-width: 1180px;
  height: auto;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
}

.dashboard-body-container {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 420px);
}

.dashboard-body-container .dashboard-body-tab {
  width: 100%;
  max-width: 1180px;
  height: auto;
  margin: 0 auto;
}

.dashboard-body-container .tab-panel-body {
  width: 100%;
  height: auto;
}

.dashboard-body-container .tab-panel-body.empty {
  min-height: calc(100vh - 420px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-body-container .tab-panel-body .no-documents {
  width: 100%;
  height: auto;
  max-width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font: normal normal 400 14px/21px var(--tf-font);
  color: var(--cortex-empty);
  text-align: center;
  gap: 16px;
}

.dashboard-body-container .tab-panel-body .no-documents strong {
  font-weight: 700;
  display: block;
}

.dashboard-body-container .tab-panel-body .dashboard-all-documents {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
}

.dashboard-body-container .tab-panel-body .dashboard-all-documents .document-card {
  width: 100%;
  height: auto;
  max-width: 410px;
  padding: 8px;
  background-color: var(--cortex-white);
  border: 1px solid #e9e9e9;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  color: var(--cortex-secondary);
  position: relative;
}

.dashboard-body-container .tab-panel-body .dashboard-all-documents .document-card .icon {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 16px;
  right: 8px;
  z-index: 1;
}

.dashboard-body-container .tab-panel-body .dashboard-all-documents .document-card .img-wrapper {
  width: 100%;
  height: 120px;
  overflow: hidden;
  margin-bottom: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.dashboard-body-container .tab-panel-body .dashboard-all-documents .document-card .card-content {
  width: 100%;
  height: auto;
  padding: 0 8px;
}

.document-card .card-content .card-subTitle {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 8px;
  font: normal normal 400 12px/130% var(--tf-font);
  color: #00022d;
}

.document-card .card-content .card-title {
  width: 100%;
  height: auto;
  font: normal normal 700 14px/21px var(--tf-font);
  color: var(--cortex-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 6px 0;
}

.document-card .card-content .card-time {
  font: normal normal 400 12px/130% var(--tf-font);
  color: var(--cortex-empty);
}

/* Image Generator */

.no-header {
  width: 100%;
  height: auto;
}

.no-header .header {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  top: 48px;
  left: 0;
  background-color: var(--cortex-white);
  border-bottom: 1px solid #e2e2e2;
  box-shadow: 0px 1px 2px rgba(244, 244, 244, 0.88);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.no-header .header.sm {
  height: 63px;
}

.no-header .header .header-title {
  width: 100%;
  height: 48px;
  padding: 0 12px;
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
}

.no-header .header .header-title .header-title-inner {
  display: flex;
  align-items: center;
}

.no-header .header .header-tab {
  width: 100%;
  height: 40px;
  padding: 0 24px;
}

.no-header main {
  margin-top: var(--cortex-header);
  min-height: calc(100vh - var(--cortex-header));
}

.new-file-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.new-file-title img:first-child {
  margin-right: 6px;
}

.image-generator-tab {
  min-height: 40px !important;
}

.image-generator-tab button.MuiButtonBase-root {
  padding: 8px 16px 6px;
  font-size: 14px;
  min-height: 30px;
}

.header-title-share {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
}

.header-title-share .profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--cortex-light-grey);
  overflow: hidden;
}

/* Image generate */

.image-tab-body {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 230px);
  padding: 0 24px;
}

.image-tab-body.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-result-empty {
  width: 100%;
  max-width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-result-empty .try-title {
  font: normal normal 400 14px/21px var(--tf-font);
  color: var(--cortex-empty);
  margin-bottom: 12px;
}

.image-result-empty ul {
  padding: 0;
  margin: 0 0 24px 0;
  list-style: none;
  text-align: center;
}

.image-result-empty ul li {
  width: fit-content;
  height: auto;
  display: inline-block;
  margin: 4px;
  font: normal normal 400 14px/21px var(--tf-font);
  color: var(--cortex-secondary);
  background-color: var(--cortex-white);
  border: var(--cortex-field-border);
  border-radius: 24px;
  padding: 6px 16px;
  cursor: pointer;
}

.image-result-empty .image-empty-card {
  width: 100%;
  height: auto;
  max-width: 355px;
  background-color: var(--cortex-white);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.image-result-empty .image-empty-card .img-block {
  width: 120px;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
}

.image-result-empty .image-empty-card .img-block .play-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.image-result-empty .image-empty-card .image-empty-card-details {
  width: 100%;
  height: auto;
}

.image-empty-card .image-empty-card-details p {
  font: normal normal 400 14px/21px var(--tf-font);
  color: var(--cortex-empty);
}

.image-empty-card .image-empty-card-details h6 {
  font: normal normal 700 14px/21px var(--tf-font);
  color: var(--cortex-primary);
  margin: 4px 0 0;
  cursor: pointer;
}

/* image search */

.image-search-container {
  width: 100%;
  height: auto;
  padding: 0 70px 0 100px;
  position: relative;
}

.image-search-container .search-result-container {
  width: 912px;
  height: auto;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}

.image-search-container .search-result-container .search-aside {
  width: 320px;
  height: auto;
  margin-top: -48px;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);
}

.search-aside .search-aside-title {
  width: 100%;
  height: 48px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  font: normal normal 400 14px/21px var(--tf-font);
  color: var(--cortex-empty);
}

.image-search-container .search-result-container .search-image-result {
  width: 544px;
  margin-left: 48px;
  height: auto;
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);
}

.search-result-container .search-image-result .image-box {
  width: 100%;
  height: auto;
}

.search-result-container .search-image-result .image-box img {
  width: 100%;
  height: auto;
}

.search-result-container .search-image-result .thumbnail-container {
  width: 100%;
  height: auto;
  margin: 24px 0;
  white-space: nowrap;
  overflow-x: auto;
  padding-bottom: 16px;
}

.search-image-result .thumbnail-container .thumbnail-wrapper {
  width: fit-content;
  min-width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
}

.search-image-result .thumbnail-container .thumbnail-wrapper .thumb {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.search-image-result .thumbnail-container .thumbnail-wrapper .thumb img {
  width: fit-content;
  height: 100%;
}

.search-image-result .thumbnail-container .thumbnail-wrapper .thumb.selected {
  border: 3px solid var(--cortext-error);
}

.search-image-result .thumbnail-container .thumbnail-wrapper .addMore {
  width: 60px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  text-align: center;
  font: normal normal 700 12px/130% var(--tf-font);
  color: var(--cortex-empty);
}

.search-image-result .thumbnail-container .thumbnail-wrapper .addMore .thumb {
  border: 1px solid #e2e2e2;
}

.search-image-result .thumbnail-container .thumbnail-wrapper .addMore img {
  max-width: 20px;
  height: auto;
}

/* context bar */

.context-container {
  width: 100%;
  height: auto;
  padding: 0 70px 0 100px;
}

.context-container .context-bar-container {
  width: 592px;
  height: auto;
  position: relative;
}

.context-container .context-bar-container .context-bar {
  width: 100%;
  height: 120px;
  padding: 8px;
  background-color: #ffffff;
  border-top: 1px solid #e2e2e2;
  box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12;
  gap: 20px;
}

/* search bar */

.image-search-container .search-bar-container {
  /* width: calc(100% - 350px); */
  height: 118px;
  position: relative;
  border: 1px solid #ecebed;
}

.image-search-container .search-bar-container .search-bar {
  width: 100%;
  height: 118px;
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid #ecebed;
  /* box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.12); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  overflow-y: scroll;
}

.image-search-container .search-bar-container .search-bar::-webkit-scrollbar {
  display: none;
}

.image-search-container .search-bar-container .search-bar.focused {
  border: 1px solid #111013;
}

.image-search-container.search-prompt {
  padding-left: 0;
  padding-right: 0;
}

/* .image-search-container.search-prompt .search-bar {
    box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.12);
} */

.image-search-container.search-prompt .search-bar-container {
  position: relative;
}

/* .image-search-container.search-prompt .search-bar-container::after {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    bottom: -14px;
    left: 50%;
    background-color: var(--cortex-white);
    transform: rotate(45deg) translateX(-50%);
} */

.image-search-container .search-bar-container .search-bar .search-bar-control {
  width: fit-content;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /*  margin-right: 16px; */
  gap: 20px;
}

.search-bar .search-bar-orange {
  width: 28px;
  height: 28px;
  background-color: var(--cortext-error);
  border-radius: 4px;
  position: relative;
}

.search-bar .search-bar-orange::before {
  content: "";
  width: 4px;
  height: calc(100% - 24px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  z-index: 1;
  background-color: var(--cortex-white);
}

.search-bar .image-search-btn,
.search-bar .image-search-trigger-btn {
  width: 28px;
  height: 28px;
  background-color: var(--cortex-white);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.search-bar .image-search-btn img,
.search-bar .image-search-trigger-btn img {
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.search-bar .image-search-btn:hover,
.search-bar .image-search-btn.active {
  background-color: var(--cortex-grey);
}

.search-bar .image-search-trigger-btn.active img {
  opacity: 1;
}

.search-bar .image-search-btn:hover img,
.search-bar .image-search-btn.active img {
  opacity: 1;
}

.search-bar input {
  width: calc(100% - 88px);
  height: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  margin-top: -8px;
  font: normal normal 400 14px/21px var(--tf-font);
}

.search-bar.with-result input {
  width: calc(100% - 320px);
}

.search-bar .search-bar-logo {
  cursor: pointer;
  margin: 5px;
}

.search-bar .disabled-search-bar-logo {
  pointer-events: none;
  margin: 5px;
}

.asset-picker {
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
  position: absolute;
  z-index: 1;
}

/* search result */

.search-result-dropdown {
  width: calc(100% - 60px);
  height: auto;
  min-height: 100px;
  background-color: var(--cortex-white);
  border-top: 1px solid #e2e2e2;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  padding: 20px;
}

.image-result-container {
  width: 100%;
  height: 400px;
  background-color: var(--cortex-white);
  border: 1px solid #e2e2e2;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);
}

.search-result-dropdown .result-list-container {
  width: 100%;
  height: auto;
  max-height: 195px;
  overflow-y: auto;
}

.popper-scrollbar {
  height: auto;
  max-height: 250px;
  overflow-y: scroll;
}

.search-result-dropdown .result-list-container ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.search-result-dropdown .result-list-container ul li {
  font: normal normal 400 14px/21px var(--tf-font);
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.search-result-dropdown .result-list-container ul li span {
  color: rgba(0, 0, 0, 1);
}

.search-result-dropdown .result-loader-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
}

.search-result-dropdown .result-loader-container .spinner {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.search-result-dropdown .result-loader-container .spinner img {
  width: 100%;
  height: auto;
}

.search-result-dropdown .result-loader-container .result-loader-title {
  font: normal normal 400 14px/21px var(--tf-font);
  color: #00022d;
}

.search-result-dropdown .result-loader-container .result-loader-desc {
  font: normal normal 400 14px/21px var(--tf-font);
  color: var(--cortex-empty);
  margin-bottom: 40px;
}

/* search aside */

.search-aside-container {
  width: 100%;
  height: auto;
  padding: 24px;
}

.search-aside-container-text {
  width: 100%;
  height: auto;
  padding: 12px;
}

.search-aside-row {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.search-aside-row .version-history-title {
  width: 100%;
  height: auto;
  padding-left: 24px;
  margin-bottom: 12px;
  position: relative;
}

.search-aside-row .version-history-title::before {
  content: "";
  position: absolute;
  border: 1px solid #9f9f9e;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  left: 0;
  top: 4px;
  z-index: 1;
}

.search-aside-row .version-history-title.active::before {
  border: 1px solid var(--cortext-error);
  background-color: var(--cortext-error);
}

.search-aside-row .version-history-title span {
  font: normal normal 400 12px/130% var(--tf-font);
  color: var(--cortex-empty);
}

.search-aside-row .version-history-thumbnails {
  width: 100%;
  height: auto;
}

.search-aside-row .version-history-thumbnails .thumb {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;
  margin: 4px;
}

.search-aside-row .version-history-thumbnails .thumb img {
  width: fit-content;
  height: 100%;
}

.search-aside-row .input-label {
  display: flex;
  align-items: center;
  gap: 8px;
  font: normal normal 400 12px/130% var(--tf-font);
  color: #00022d;
}

.aside-download-btns {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin: 8px 0 32px;
}

.aside-download-btns.download-full {
  flex-direction: column;
}

.customControls:first-of-type {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.customControls:last-of-type {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.customControls {
  box-shadow: none !important;
}

.customControls p.MuiTypography-root {
  font-weight: 700;
}

.customControls .MuiAccordionSummary-root {
  padding: 0 24px !important;
}

.customControls .MuiAccordionDetails-root {
  padding: 0 24px !important;
}

.parameter-control-row {
  width: 100%;
  height: auto;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 12px;
}

.parameter-control-row span {
  font: normal normal 400 14px/19px var(--tf-font);
  color: #00022d;
}

.parameter-control-row .control-col {
  width: 100%;
  max-width: 150px;
  height: auto;
}

p.control-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

p.control-title span {
  width: fit-content;
  min-width: 33px;
  height: 27px;
  background-color: #fff4f5;
  border-radius: 24px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 700 14px/19px var(--tf-font);
  color: #ff6060;
}

/* ------ */
/* Assets */
/* ------ */
.asset-card {
  width: 100%;
  max-width: 228px;
}

.cards-outer-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 4.5px;
}

.css-12mxt1l-MuiCardContent-root:last-child {
  padding-bottom: 10px !important;
}

.new-card {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* min-height: 226px; */
  aspect-ratio: 1/1;
  width: 226px;
  cursor: pointer;
}

.hover:hover {
  cursor: pointer;
}

@media (max-width: 600px) {
  .new-card {
    min-height: 226px;
  }

  .center-on-mobile {
    justify-content: center !important;
  }
}

.new-card.set-aspect-ratio {
  aspect-ratio: unset;
}

.uploading-asset {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
}

.uploading-asset img {
  margin-bottom: 5px;
  margin-right: 5px;
  animation: rotate 1.5s linear infinite;
}

.full-img {
  height: 100%;
}

/* add rotation animate */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.upload-desc {
  display: flex;
  flex-direction: column;
}

.uploadInfoInput {
  background-color: transparent;
  border: unset;
  font-size: 0.875rem;
}

.blackSpinnerImg {
  margin-right: 8px;
  filter: brightness(0) saturate(100%);
  animation: rotate 1.5s linear infinite;
}

.redSpinnerImg {
  margin-right: 8px;
  filter: brightness(0) saturate(100%) invert(36%) sepia(85%) saturate(6536%) hue-rotate(341deg) brightness(102%)
    contrast(98%);
  animation: rotate 1.5s linear infinite;
}

.redCheck {
  filter: brightness(0) saturate(100%) invert(36%) sepia(85%) saturate(6536%) hue-rotate(341deg) brightness(102%)
    contrast(98%);
  margin-right: 8px;
}

.squareCard {
  aspect-ratio: 1/1;
}

.flex-grow-1 {
  flex-grow: 1;
}

.mr-8 {
  margin-right: 8px;
}

.unstyledTabs button.MuiTab-root {
  font-size: 1rem;
}

.unstyledTabs .MuiTabs-indicator {
  display: none;
}

.unstyledTabs .Mui-selected {
  color: #141011 !important;
}

.rightSidebarTabs .MuiTabs-flexContainer .css-c3zwvi-MuiButtonBase-root-MuiTab-root {
  min-width: fit-content !important;
}

.rightSidebarTabs button.MuiTab-root {
  padding: 0;
  font-size: 14px;
}

.sidebar-tabs ~ .MuiTabPanel-root {
  padding: unset !important;
}

/* ------------------- */
/* Project page styles */
/* ------------------- */

.card-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  min-width: 228px !important;
  padding: 17px;
  cursor: pointer;
}

.new-action-menu ul {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.new-action-menu-dropdown {
  margin-top: 22px;
  z-index: 1000;
}

.new-action-menu-dropdown .MuiPaper-root {
  border-radius: 0px;
  background-color: var(--cortex-dark-header);
}

.new-action-menu-dropdown ul {
  padding-top: 0px;
  padding-bottom: 0px;
}

.new-action-menu-dropdown ul .MuiListItemText-root .MuiTypography-root {
  color: var(--cortex-white);
  font-size: 14px;
}

.new-action-menu-dropdown ul li:hover {
  background-color: #302e35;
}

.new-action-menu ul li {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
  /* add box shadow to bottom*/
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
}

.add-bottom-tab-menu {
  box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.12) !important;
  margin-bottom: 10px !important;
}

.add-bottom-tab-menu .MuiPaper-root {
  box-shadow: unset;
}

.add-bottom-tab-menu ul .MuiListItemText-root .MuiTypography-root {
  font-size: 14px;
}

.add-bottom-tab-menu .MuiListItemIcon-root {
  min-width: 21px !important;
}

.add-bottom-tab-menu .MuiListItemIcon-root img {
  width: 14px;
}

.add-bottom-tab-menu .black-image-icon {
  filter: brightness(0) saturate(100%);
}

@media (max-width: 1036px) {
  .wrapOnMobile {
    flex-wrap: wrap;
  }
}

.divider {
  background: #d9d9d9;
  box-shadow: 0px 1px 0px #ffffff;
  margin-left: 16px;
  margin-right: 16px;
  width: 100%;
  height: 1px;
}

.ai-recommendation-badge {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #fd243e;
  padding: 0.5rem;
  color: var(--cortex-white);
  font-weight: 700;
  font-size: 0.75rem;
}

.cortex-btn-dark {
  background-color: #454348;
  color: #c2bdc8;
  min-height: 32px;
}

.navbar_account_typography {
  color: #c2bdc8 !important;
  display: flex;
  cursor: pointer;
  padding: 2px;
}

.navbar_account_color {
  color: #c2bdc8 !important;
  margin: 0 4px;
}

.account-switcher {
  margin-top: 12px !important;
}

button.cortex-btn {
  background-color: unset;
  border-radius: 0px;
  border: none;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 21px;
  cursor: pointer;
}

.cortex-btn-black {
  background-color: var(--cortex-dark-header) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  width: 87px !important;
}

.cortex-btn-grey {
  background-color: #f1f1f2 !important;
  width: 87px !important;
  color: var(--cortex-dark-header) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

button.cortex-btn-dark {
  background-color: #454348;
  color: #c2bdc8;
  min-height: 32px;
}

.editor-container {
  width: 100%;
  border: 1px solid green;
  padding: 1rem;
  height: 300px;
}

/* Sidebar */
.sidebar-outer {
  width: 240px;
  background-color: var(--cortex-white);
  height: calc(100vh - 48px);
  position: fixed;
  top: 48px;
  box-shadow: 0px 0.930909px 3.72364px rgba(0, 0, 0, 0.16);
  z-index: 1;
}

.sidebar-outer.left {
  /* padding-top: 40px; */
  position: sticky;
  top: var(--cortex-header);
  left: 0;
  right: unset;
  margin-right: 33px;
  /* padding-left: 24px; */
  min-width: 240px;
  padding-bottom: var(--cortex-bottom-bar);
}

.sidebar-outer.right {
  position: sticky;
  min-width: 320px;
  top: var(--cortex-header);
  left: unset;
  right: 0;
  margin-left: 33px;
  padding-bottom: var(--cortex-bottom-bar);
}

.sidebar-inner {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar-outer.right .sidebar-inner .sidebar-right-separator {
  width: 100%;
  height: 2px;
  background: #ecebed;
}

.sidebar-tabs {
  position: sticky;
  top: 0;
  background-color: var(--cortex-white);
  z-index: 1;
}

.sidebar-list {
  list-style: none;
  padding-left: 24px;
}

.sidebar-sublist {
  padding-left: 12px;
  list-style: none;
  cursor: pointer;
}

.sidebar-item {
  position: relative;
  font-weight: bold;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
  padding-top: 8.5px;
  padding-bottom: 8.5px;
  cursor: pointer;
}

.sidebar-item.active::before {
  content: "";
  position: absolute;
  left: -24px;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: var(--cortex-dark-header);
}

.sidebar-subitem {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Bottom bar for Canvas Page */
.bottombar-outer {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  overflow-x: scroll;
  height: var(--cortex-bottom-bar);
  filter: drop-shadow(0px -1px 4px rgba(0, 0, 0, 0.12));
  background-color: var(--cortex-white);
}

.bottombar-outer::-webkit-scrollbar {
  display: none;
}

.bottombar-outer .bottombar-buttons {
  background-color: var(--cortex-white);
  padding: 15px 13px;
  display: flex;
  position: sticky;
  right: 0px;
  align-items: center;
}

.bottombar-outer .bottombar-buttons .scroll-button {
  background-color: transparent;
  outline: none;
  border: none;
  margin-right: 13px;
  cursor: pointer;
}

/**
  * Bottom bar and bottom tab hover CSS
  */
.bottom-tab {
  min-width: 160px;
  max-width: 180px;
  white-space: nowrap;
  overflow: clip;
  padding: 4px;
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0px 6px 8px;
  cursor: pointer;
}

.bottom-tab.active {
  color: var(--cortex-dark-header);
  border-top: 1px solid var(--cortex-dark-header);
  background-color: var(--cortex-grey);
}

.bottom-tab.active .bottom-vertical-button {
  display: flex !important;
}

.bottom-vertical-button {
  display: none !important;
  border-radius: 0px !important;
  width: 20px !important;
  height: 20px !important;
  border: 1px solid #d8d7d9 !important;
  background-color: var(--cortex-white) !important;
  margin-right: 5px !important;
  margin-top: -3px !important;
}

.bottom-vertical-button svg {
  height: 16px !important;
}

.bottom-tab:hover {
  background-color: var(--cortex-grey);
  color: var(--cortex-dark-header);
}

.bottom-tab:hover .bottom-vertical-button {
  display: flex !important;
}

.bottom-tab input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #4e4d52;
}

.bottom-tab.active input {
  color: var(--cortex-dark-header);
}

.prompt {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
  /* background: var(--cortex-white); */
  /* box-shadow: 0px 0.930909px 3.72364px rgba(0, 0, 0, 0.16); */
  /* padding: 13px; */
  max-width: 512px;
  /* min-width: 512px; */
  width: 100%;
}

.new-prompt {
  max-width: 512px;
  height: 36px;
  background: #f1f1f2;
  margin-bottom: 10px;
}

.prompt .prompt-title {
  font-family: var(--tf-generated-content-font) !important;
  font-size: 16px;
  line-height: 0px;
  color: #0a0a0a;
  margin-left: -4px;
}

.prompt.new-prompt {
  background: #f1f0f2;
  cursor: pointer;
}

.prompt.new-prompt .prompt-title {
  color: var(--cortex-dark-header);
  opacity: 0.6;
}

/* Custom Form elements for AI Prompt drawer */
.prompt-builder-header {
  padding: 10px;
  position: relative;
}

.prompt-builder-header .collapseIcon {
  position: absolute;
  filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(3%) hue-rotate(249deg) brightness(101%)
    contrast(101%);
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.prompt-builder-header .prompt-builder-header-text {
  color: #fd243e !important;
  font-size: 16px;
  font-weight: 450;
}

.cortex-form-input label {
  font-size: 14px;
  top: -8px;
}

.cortex-form-input label.Mui-focused {
  top: 0px;
}

.cortex-form-input .css-pkdde9-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 14px;
}

.cortex-form-input .css-1qqsdnr-MuiAutocomplete-root .MuiInput-root .MuiInput-input {
  padding-left: 6px !important;
}

.cortex-form-input .css-1ltqs9b-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6px;
}

.css-upvyc3-MuiInputBase-root-MuiInput-root input {
  font-size: 14px;
}

.css-1598uqf-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 14px !important;
  padding-left: 10px !important;
}

.css-upvyc3-MuiInputBase-root-MuiInput-root:before {
  border-bottom-color: #ecebed !important;
  border: 1px solid #ecebed;
  padding: 1px;
}

.property-section-head-text {
  font-weight: 600 !important;
  font-size: 12px !important;
  flex: 1 !important;
}

.property-section-body .each-property {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.property-section-body .property-label {
  font-weight: 400;
  font-size: 12px;
}

.property-section-body .property-select {
  border-radius: 0;
  font-size: 12px;
  width: 125px;
  height: 21px;
  position: relative;
  border-color: #dbdbdb;
  color: var(--cortex-dark-header);
}

.property-section-body .property-select div#property-select {
  border: unset;
  display: flex;
  align-items: center;
  gap: 3px;
}

.property-section-body .property-select .MuiSelect-select {
  border: 1px solid #dbdbdb;
  padding: 5px 10px;
}

/* 07-12-2022 */

.asset-type-select {
  background-color: transparent !important;
  height: 21px !important;
  border-bottom: unset;
  outline: unset;
  border: 1px solid #ecebed !important;
  color: var(--cortex-dark-header);
}

.asset-type-select div#asset-type-select {
  padding-left: 10px;
}

.asset-type-select:focus-visible {
  background-color: transparent;
}

.asset-type-select.css-gxrupc-MuiInputBase-root-MuiInput-root-MuiSelect-root::before {
  display: none !important;
}

select.cortex-form-input {
  border: 1px solid #ecebed;
  font-size: 13px;
  border-radius: 0px;
  padding-block: 1px;
  color: var(--cortex-dark-header);
  height: 28px;
  font-family: var(--tf-generated-content-font) !important;
}

select.cortex-form-input:invalid {
  color: #a09fa1;
}

select.cortex-form-input:focus-visible {
  outline: unset;
}

.assistant-welcome-text {
  font-family: var(--tf-generated-content-font) !important;
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* or 120% */
  letter-spacing: -0.02em;
  color: #6b6a6c;
  border-bottom: 1px solid rgba(143, 143, 143, 0.4);
  margin-bottom: 1rem !important;
  padding-bottom: 15px;
}

.assistant-section-title {
  font-family: var(--tf-generated-content-font) !important;
  font-style: italic;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 12px !important;
  color: var(--cortex-dark-header) !important;
  letter-spacing: -0.02em !important;
  border-top: var(--divider);
  padding-top: 12px !important;
  margin-bottom: 8px !important;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  margin-top: var(--cortex-header) !important;
}

.css-wq5ooo-MuiModal-root-MuiDrawer-root .MuiDrawer-paper {
  margin-top: var(--cortex-header) !important;
  height: calc(100% - var(--cortex-header));
}

.css-1qqsdnr-MuiAutocomplete-root .MuiInput-root .MuiInput-input {
  padding: 4px 4px 4px 10px !important;
}

/* 08-12-2022 */
.variations-sidebar {
  width: 100%;
  /* border: 1px solid red; */
  height: calc(100vh - 440px);
  overflow-y: auto !important;
  overflow-x: hidden;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.variations-sidebar .variations-sidebar-image-wrapper {
  /* aspect-ratio: 1/1; */
  position: relative;
  margin: 1px;
  /* min-width: 170px; */
}

.context-menu-image-buttons-wrapper {
  /* display: flex; */
  gap: 0.1em;
  position: absolute;
  top: 10px;
  right: 10px;
  transition: 1s;
  display: none;
}

.label-text-variation-tag {
  pointer-events: none !important;
  background-color: #f1f1f2 !important;
  border-color: #f1f1f2 !important;
  height: 32px !important;
  width: 93px !important;
}

.labels-div {
  display: flex;
}

.label-text-variation-tag:hover {
  background-color: #f1f1f2 !important;
}

.variations-sidebar .variations-sidebar-image-wrapper .variations-sidebar-image {
  cursor: pointer;
  display: block;
  border: none !important;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: var(--cortex-white);
}

.variations-sidebar .variations-sidebar-image-wrapper .variations-gray-image-loader {
  display: block;
  border: none !important;
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-color: var(--cortex-white);
}

.context-menu-image-buttons-wrapper.image-as-input {
  position: absolute;
  right: 10px;
  height: -moz-fit-content;
  height: min-content;
  border-radius: 0;
  display: none;
  align-items: flex-end;
  left: 10px;
  top: calc(100% - 42px); /* 32px icon height - 10px bottom gap */
}

.image-as-input {
  color: var(--cortex-white) !important;
  background: rgba(17, 16, 19, 0.2) !important;
  backdrop-filter: blur(5px);
  border-radius: 0;
  width: 2rem;
  height: 2rem;
}

.image-as-input:hover {
  background: rgba(17, 16, 19, 0.5) !important;
  transition: 0.3s;
}

.context-menu-image-buttons-wrapper.image-as-input > img {
  width: 16px;
  filter: invert(1);
}

.variations-sidebar .variations-sidebar-image-wrapper:hover .context-menu-image-buttons-wrapper {
  right: 10px;
}

.variations-sidebar .variations-sidebar-image-wrapper:hover .context-menu-image-buttons-wrapper {
  display: flex;
}

.context-menu-image-buttons-wrapper .context-menu-image-button {
  color: white;
  background: rgba(17, 16, 19, 0.2);
  backdrop-filter: blur(5px);
  border-radius: 0;
  width: 2rem;
  height: 2rem;
}

.context-menu-image-buttons-wrapper .context-menu-image-button.text-variation-menu {
  color: black;
  background-color: white;
  border: 1px solid #d8d7d9;
}

.context-menu-image-buttons-wrapper .context-menu-image-button:hover {
  background: rgba(17, 16, 19, 0.5);
  transition: 0.3s;
}

.asset-action-buttons-root {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 6px;
}

.asset-context-menu-bg {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5.11727px);
  box-shadow: 0px 0.930909px 3.72364px rgba(0, 0, 0, 0.16);
}

.variations-sidebar .variations-text {
  width: 100%;
  padding: 12px 12px 0 12px;

  /* position: relative; */
  cursor: pointer;
}

.variations-sidebar .variations-text.active {
  border-left: 2px solid #0a0a0a;
}

.variations-text .context-menu-image-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  top: unset;
  position: relative;
  padding-bottom: 12px;
  background-color: white;
}

.more-like-this-icon {
  width: fit-content !important;
  align-items: center !important;
  gap: 8px !important;
}

.generated-content-font {
  font-family: var(--tf-generated-content-font) !important;
}

.regular-font {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: var(--tf-font-color) !important;
  /* letter-spacing: -0.02em; */
  line-height: 20px !important;
}

.titleFont {
  font-weight: 400;
  color: var(--tf-font-color);
}

.variations-sidebar h1,
.editor-canvas .ce-block h1 {
  line-height: 34px;
  font-size: 28px;
  font-weight: 400;
  color: var(--tf-font-color);
}

.generatedContentPrompt.generatedImagePrompt img.main-image {
  width: 100%;
  object-fit: contain;
  /* aspect-ratio: 1/1;*/
}

.generatedImagePrompt {
  max-width: 512px;
  padding: unset;
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
}

.generatedImagePrompt:hover .context-menu-image-buttons-wrapper {
  display: flex;
}

/* Full screen image dialog */
.fullscreen-image-dialog:hover .context-menu-image-buttons-wrapper {
  display: flex;
}

/* History Sidebar */
.history-sidebar {
  margin-top: 8px;
}

.history-section {
  padding: 8px;
  margin: 10px 0;
}

.history-section .history-section-user-name {
  font-weight: 450;
  font-size: 13px;
  color: #0d163a;
}

.history-section .history-section-caption-wrapper {
  display: flex;
  align-items: center;
  color: #0d163a;
  opacity: 0.5;
  gap: 0.4em;
}

.history-section .history-section-caption-wrapper .history-section-action-icon * {
  font-size: 14px;
}

.history-section .history-section-caption {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #0d163a;
  /* opacity: 0.5; */
}

.history-section .history-section-image {
  width: 100px;
  height: 100px;
}

.history-section .history-section-time {
  /* width: 100%; */
  text-align: right;
  white-space: nowrap;
}

.history-section-text-box .history-section-text {
  font-family: var(--tf-generated-content-font);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  white-space: pre-wrap;
  letter-spacing: -0.02em;
  color: #0a0a0a;
}

.highlight {
  background: rgba(82, 82, 255, 0.1);
}

/* breadcrumb */
.breadcrumb .MuiBreadcrumbs-separator {
  color: #a09fa1;
}

.breadcrumb-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 112%;
  letter-spacing: 0.01px;
  color: #a09fa1;
  transition: border 0.2s ease-in-out;
  border-bottom: 1.5px solid transparent;
}

.text-stack-dialog-paper {
  position: absolute !important;
  top: 5%;
  display: flex;
  background: #ffffff;
  border: 1px solid #dee2e6;
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.12),
    0px 20px 20px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  min-width: 25em;
  min-height: 10em;
  max-width: 90% !important;
  max-height: 85% !important;
  /* transform: translateY(-80%); */
}

.text-stack-dialog-paper .document-template-select-dialog-body {
  max-width: 800px;
  padding: 0 20px;
}

.document-template-select-dialog-body {
  padding-left: 10px !important;
}
.prompt-dialog-full-screen {
  border: none;
  box-shadow: none;
  justify-content: center;
  align-self: center;
  width: 100%;
  height: 100%;
}

.prompt-dialog-with-header {
  padding-top: 45px;
}

.template-selection-full-screen {
  top: 0;
  display: flex;
  align-content: center;
  max-height: 100% !important;
}

.text-stack-dialog-paper .document-template-select-dialog-body .template-card-tile {
  border: 1px solid #ecebed;
  border-radius: unset;
  box-shadow: unset;
  aspect-ratio: 1/1;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.text-stack-dialog-paper .document-template-select-dialog-body .template-card-tile:hover {
  background-color: #f1f1f2;
}

.text-stack-dialog-paper .document-template-dialog-paper-sheet {
  width: 100%;
  background: #ffffff;
  padding: 20px;
}

.template-form-bordered {
  padding: 20px;
  border: 1px solid #ecebed;
  height: 100%;
}

.text-stack-dialog-title {
  font-weight: 450;
  font-size: 20px;
  color: #111013;
  display: flex;
  align-items: center;
}

.text-stack-dialog-paper .text-stack-dialog-button {
  background: #f1f1f2;
  font-weight: 400;
  font-size: 14px;
  color: #111013;
  border-radius: 0;
  text-transform: none;
}

.text-stack-dialog-paper .text-stack-dialog-button:hover {
  background: #e1e1e2;
}

.document-template-dialog-blurb-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #111013;
  margin-top: 12px;
}

.document-template-dialog-preview-text {
  color: #42526e;
  margin-left: 8px;
  font-size: 7px;
  background-color: #dfe1e2;
  padding: 4px;
  padding-top: 6px;
}

.project-picker-paper {
  height: 85% !important;
  background-color: #f1f1f1 !important;
}

.share-dialog-paper {
  display: flex;
  gap: 1em;
  padding: 20px 20px;
  background: #ffffff;
  border: 1px solid #dee2e6;
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.12),
    0px 20px 20px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  width: 440px;
  /* transform: translateY(-80%); */
}

.share-dialog-paper .share-dialog-title {
  font-weight: 450;
  font-size: 20px;
  color: #111013;
}

.share-dialog-paper .share-dialog-button {
  background: #f1f1f2;
  backdrop-filter: blur(5px);
  font-weight: 400;
  font-size: 14px;
  color: #111013;
  border-radius: 0;
  text-transform: none;
}

.share-dialog-paper .share-dialog-button:hover {
  background: #e1e1e2;
}

/* CSS FOR NEW CANVAS */
.font-spectral {
  font-family: var(--tf-generated-content-font) !important;
}

.search-prompt-text {
  position: relative;
  margin-bottom: 8px;
}

.search-prompt-text textarea {
  width: 100%;
  border: 1px solid #ecebed;
  padding: 15px 13px 15px 53px;
  font-size: 14px;
  line-height: 150%;
  transition: border 0.1s ease;
}

.search-prompt-text textarea:focus {
  border: 1px solid #111013;
}

.search-prompt-text textarea::placeholder {
  color: #a09fa1;
}

.search-prompt-text .prompt-icon {
  position: absolute;
  top: 13px;
  left: 13px;
}

.search-prompt-text textarea:focus-visible {
  outline: none;
  border: 1px solid #111013;
}

.generate-prompt-wrapper {
  position: relative;
  /* flex-grow: 1; */
  /* min-width: calc(552px + 8px); */
  /* max-width: calc(890px + 8px); */
  display: flex;
  flex-direction: column;
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.12),
    0px 20px 20px rgba(0, 0, 0, 0.08);
}

.report-generate-prompt-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.resize-buttons {
  position: absolute;
  right: 0px;
  display: flex;
  opacity: 0;
  gap: 1px;
  z-index: 10;
}

.resize-buttons.compose-resize {
  position: sticky;
  margin-right: 4px;
  justify-content: flex-end;
}

.generate-prompt-wrapper:hover .resize-buttons,
.compose-area-wrapper-v2:hover .resize-buttons {
  opacity: 1;
}

.resize-buttons img {
  cursor: pointer;
  width: 25px;
}

.generate-prompt-collapsed,
.compose-area-collapsed {
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 32px;
  background-color: var(--cortex-white);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  z-index: 1;
}

.generate-prompt-collapsed p,
.compose-area-collapsed p {
  transform: rotate(-90deg);
  white-space: nowrap;
  color: #11101366;
  font-size: 12px;
}

.generate-prompt-collapsed {
  left: 0px;
}

.compose-area-collapsed {
  right: 0px;
  margin-left: 4px;
}

/* @media screen and (min-width: 1200px) {
  .generate-prompt-wrapper {
    flex-grow: 1;
  
}

*/
.generate-prompt-wrapper .empty-variation-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

.magic-edit-variations::-webkit-scrollbar-thumb,
.custom-scrollbar::-webkit-scrollbar-thumb,
.variations-sidebar::-webkit-scrollbar-thumb,
.popper-scrollbar::-webkit-scrollbar-thumb,
.compose-area-wrapper-v2::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  /* border: 1px solid #b7c0c2; */
  background-clip: content-box;
}

.magic-edit-variations::-webkit-scrollbar-thumb,
.custom-scrollbar::-webkit-scrollbar-thumb,
.variations-sidebar:hover::-webkit-scrollbar-thumb,
.popper-scrollbar:hover::-webkit-scrollbar-thumb,
.compose-area-wrapper-v2:hover::-webkit-scrollbar-thumb {
  background-color: #b7c0c2;
}

.magic-edit-variations::-webkit-scrollbar-thumb:hover,
.custom-scrollbar::-webkit-scrollbar-thumb:hover,
.variations-sidebar::-webkit-scrollbar-thumb:hover,
.popper-scrollbar::-webkit-scrollbar-thumb:hover,
.compose-area-wrapper-v2::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.custom-scrollbar::-webkit-scrollbar,
.variations-sidebar::-webkit-scrollbar,
.popper-scrollbar::-webkit-scrollbar,
.compose-area-wrapper-v2::-webkit-scrollbar {
  width: 4px;
}

.magic-edit-variations::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.magic-edit-variations::-webkit-scrollbar-track,
.custom-scrollbar::-webkit-scrollbar-track,
.variations-sidebar::-webkit-scrollbar-track,
.popper-scrollbar::-webkit-scrollbar-track,
.compose-area-wrapper-v2::-webkit-scrollbar-track {
  background-color: transparent;
}

.compose-area-wrapper-v2 {
  position: relative;
  flex-grow: 1;
  padding-left: 21px;
  padding-right: 0px;
  padding-top: 0px;
  overflow-y: auto;
  height: calc(100vh - 48px);
}

.compose-area-reports-v2 {
  position: relative;
  flex-grow: 1;
  padding-left: 21px;
  padding-right: 0px;
  padding-top: 0px;
  overflow-y: auto;
  height: calc(100vh);
}
.editor-area {
  height: 100%;
  padding-bottom: 430px !important;
}

.canvas-editor-pane-v2 {
  min-height: calc(100vh - 94px);
  /* height: 100%; */
  margin-right: 24px;
  /* margin-top: 24px; */
  /* margin-bottom: 24px; */
}

.canvas-editor-pane-embed {
  min-height: calc(100vh - 94px);
  margin: 0 10px 74px 10px;
}

.canvas-editor-pane-empty {
  min-height: calc(100vh - 94px);
  height: 100%;
  margin: 24px 24px 24px 24px;
}

.canvas-editor-pane-empty {
  min-height: calc(100vh - 94px);
  height: 100%;
}

.generate-prompt-box {
  max-width: 100%;
  background-color: #ffffff;
  padding: 16px 40px 32px 40px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  position: sticky;
  top: 0px;
  z-index: 1;
  height: 321px;
}

.generate-prompt-box-btn {
  font-size: 14px !important;
  padding-left: 16px !important;
  width: 100% !important;
  background-color: #f1f1f2 !important;
  text-align: center !important;
  color: #111013 !important;
  transition: background-color 0.4s ease-in-out;
  height: 32px;
}

.generate-prompt-box-btn-hil {
  font-size: 14px !important;
  padding: 6px !important;
  width: 100% !important;
  background-color: #f1f1f2 !important;
  text-align: center !important;
  color: var(--cortex-bg) !important;
  background-color: var(--cortex-dark-header) !important;
  transition: background-color 0.4s ease-in-out;
  height: 32px;
}

.generate-prompt-box-btn-hil.hil-regen-btn-disabled {
  background-color: #11101381 !important;
}

.generate-prompt-box-btn-white {
  font-size: 14px !important;
  padding-left: 16px !important;
  width: 100% !important;
  background-color: var(--cortex-bg) !important;
  text-align: center !important;
  color: var(--cortex-dark-header) !important;
  transition: background-color 0.4s ease-in-out;
}

.regenerate-image-box-btn-dark {
  font-size: 14px !important;
  padding-left: 16px !important;
  background-color: #111013 !important;
  text-align: center !important;
  color: #ffffff !important;
  transition: background-color 0.4s ease-in-out;
  border-radius: 0px !important;
  box-shadow: none;
  text-transform: none !important;
}

.btn-disabled {
  background-color: #11101381 !important;
}

.regenerate-image-box-btn-light {
  font-size: 14px !important;
  padding-left: 16px !important;
  background-color: #f1f1f2 !important;
  text-align: center !important;
  color: #111013 !important;
  transition: background-color 0.4s ease-in-out;
  text-transform: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.image-crop-container {
  border: 1px solid #dddddd;
  width: 200px;
  height: 200px;
  position: relative;
}

.fit-image img {
  aspect-ratio: 1/1;
  object-fit: contain;
}

.image-crop-container-tag {
  float: left;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  background-color: #92ad40;
  padding: 5px;
  color: #ffffff;
  font-weight: bold;
}

.imageCropDialogTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.generate-prompt-box-btn:hover {
  background-color: #e1e1e2 !important;
}

/* output area in canvas */
.output-area-wrapper {
  /* padding: 28px 40px; */
  max-width: 1020px;
  margin: 0px auto;
  width: 100%;
  box-sizing: content-box;
  /*Need to specifiy height for scroll to appear separately, 266 px is height of compose pane*/
  /* height: calc(100vh - 380px); */
  /* overflow: auto !important; */
}

/* 
.output-area-wrapper::-webkit-scrollbar {
  display: none;
} */

/* .output-area-wrapper {
      padding: 28px 40px;
      max-width: 940px;
      width: inherit;
      margin: 0px auto;
      box-sizing: content-box;
}
*/
.variations-container {
  padding-left: 40px;
  padding-right: 40px;
}

.image-edit-grid-sidebar {
  background-color: var(--cortex-grey);
}

.text-variations-card {
  background-color: var(--cortex-white);
  margin-bottom: 12px !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  min-height: 150px;
  max-height: 297px;
  overflow-y: hidden;
  width: 100%;
}

.chat-variations-card {
  margin-bottom: unset !important;
  box-shadow: unset !important;
  min-height: unset !important;
  max-height: 297px;
  overflow-y: hidden;
  width: 100%;
}

.verticalScroll {
  overflow-y: scroll;
}

/* set width of scrollbars to 2px with border radius of 10px text-variations-card */
.text-variations-card::-webkit-scrollbar {
  display: none;
}

.text-variations-card .text-action-wrapper {
  position: sticky;
  bottom: 0px;
  /* padding-bottom: 12px; */
  padding-top: 47px;

  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, #ffffff 100%);
  width: 100%;
  padding-left: 9px;
}

.generate-prompt-type {
  background: unset !important;
  pointer-events: all;
  color: #111013 !important;
}

.generate-prompt-type .MuiSelect-select {
  padding: 12px 5px 6px 5px;
  font-size: 20px;
  font-weight: 600;
}

.prompt-entry-select .MuiSelect-select {
  font-weight: 400;
}

.generate-prompt-type fieldset {
  border: unset !important;
}

.generate-prompt-type-item {
  font-weight: 600 !important;
}

/**
* EditorJS Styles
*/
.ce-inline-toolbar--showed {
  height: 48px !important;
  /* width: 320px !important; */
  font-family: var(--tf-generated-content-font) !important;
  /* transform: translateY(-80px) translateX(-50%) !important; */
}

.ce-inline-tool-input {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
}

.ce-toolbar__plus {
  background: #ffffff !important;
}

.ce-toolbar__plus:hover {
  background: #efefef !important;
}

.ce-toolbar__plus svg {
  color: #b2b1af !important;
}

.ce-toolbar__settings-btn:hover {
  background: #efefef !important;
}

.ce-toolbar__settings-btn svg {
  color: #b2b1af !important;
}
.ce-toolbar__settings-btn {
  background: #ffffff !important;
}

.ce-toolbar__actions {
  /* right: "unset" !important; */
  left: 0px !important;
  width: max-content !important;
  /* position: unset !important; */
}

.ce-inline-toolbar__buttons {
  height: 48px !important;
  padding: 4px !important;
}

.ce-inline-toolbar__dropdown {
  /* margin-left: 4px !important; */
  padding: 4px !important;
}

.ce-inline-toolbar__dropdown-content {
  margin-left: 10px !important;
}

.ce-conversion-toolbar__label {
  display: none !important;
}

.ce-inline-toolbar__dropdown-arrow {
  margin-left: 2px !important;
  padding: 4px !important;
  /* margin-right: 2px !important; */
}

.ce-inline-toolbar__dropdown-content svg {
  padding: 1px !important;
}

.ce-inline-tool {
  height: 36px !important;
  width: 36px;
  /* margin-left: 4px !important;
  margin-right: 4px !important; */
}

.ce-inline-tool--active {
  background: #f1f1f2 !important;
  color: #111013 !important;
}

.ce-inline-tool svg {
  width: 24px !important;
  height: 22px !important;
  font-family: var(--tf-generated-content-font) !important;
}

.ce-block--focused {
  border: unset !important;
}

.ce-block--selected .ce-block__content {
  background-color: #f0f0f0 !important;
}

/**
Overwrite editorjs table to have a border. 
  By default border-left: 0px
  Use typeface font.
*/
.tc-wrap {
  border: 1px solid #f1f1f2 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: var(--tf-font-color) !important;
  font-family: var(--tf-generated-content-font) !important;
  color: #0a0a0a66;
  margin-top: unset !important;
}

/**
Overwrite editorjs table to have a background for Header row. By default border-left: 0px
*/
.tc-row [heading="Heading"] {
  background-color: #f0f0f0 !important;
}

.cdx-settings-button {
  width: 26px !important;
  height: 36px !important;
  margin-left: 4px !important;
}

.cdx-settings-button--active {
  width: 26px !important;
  height: 36px !important;
  margin-left: 4px !important;
}

.cdx-settings-button svg {
  width: 26px !important;
  height: 26px !important;
  box-shadow: rgba(201, 201, 204, 0.48) 0px 0px 0px 1px;
  box-sizing: border-box;
}

.cdx-settings-button--active svg {
  width: 26px !important;
  height: 26px !important;
  box-shadow: rgba(201, 201, 204, 0.48) 0px 0px 0px 1px;
  box-sizing: border-box;
}

/**
* THe below two classes are used to make the editor blocks align center
* DO NOT REMOVE
*/
.editor-canvas .ce-block {
  max-width: 512px !important;
  margin: auto !important;
  display: block !important;
}

.codex-editor {
  display: block !important;
  margin: auto !important;
}

.action-button {
  font-size: 14px !important;
  line-height: 21px !important;
  letter-spacing: -0.02em !important;
  border-radius: 0 !important;
  padding: 10px 20px !important;
  text-transform: none !important;
}

.disabled {
  opacity: 0.5;
  cursor: default !important;
  pointer-events: none !important;
}

.action-button-primary {
  color: #fff !important;
  background-color: #111013 !important;
}

.action-button-red {
  color: #fff !important;
  background-color: var(--cortex-red) !important;
}

.action-button-white {
  color: #111013 !important;
  background-color: #ffffff !important;
}

.action-button-secondary {
  color: #111013 !important;
  background-color: #f1f1f2 !important;
}

/* ********************** */
/* MEDIA QUERY FOR MOBILE */
/* ********************** */

.mobile-canvas-tabs .MuiTabs-scroller {
  background-color: var(--cortex-dark-header);
}

.mobile-tabs-wrapper .mobile-canvas-tabs .css-c3zwvi-MuiButtonBase-root-MuiTab-root {
  color: #a09fa1 !important;
  font-size: 20px;
  font-weight: 450;
}

.mobile-tabs-wrapper .mobile-canvas-tabs .MuiTabs-indicator {
  background-color: #ffffff !important;
}

.mobile-tabs-wrapper .mobile-canvas-tabs .css-c3zwvi-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: white !important;
}

.cortex-btn.header-share-btn {
  padding: 4px 5.5px;
  min-height: unset;
}

.cortex-btn.header-menu-btn {
  height: 32px;
  width: 32px;
}

.share-icon {
  display: none;
}

.cortex-btn.tabs-menu-btn {
  display: none;
  padding: 2.5px 4px;
  min-height: unset;
}

.mobile-tabs-wrapper {
  display: none;
}

/* three dot menu */
.three-dot-menu .MuiPaper-root {
  background: #111013;
  padding-left: 16px;
  padding-right: 16px;
}

.three-dot-menu .MuiListItemText-primary {
  color: white;
}

.three-dot-menu .MuiMenuItem-root {
  min-height: 37px;
}

/* Magic Edit Dialog classes */
.height-100-percent {
  z-index: 3000 !important;
  border-radius: 2px !important;
}

.margin-left-0 {
  margin-left: 0px !important;
}

.carousel {
  position: fixed;
  width: 32px;
  height: 32px;
  top: 50%;
  transform: translate(-100%);
  background-color: rgb(17 16 19 / 20%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  backdrop-filter: blur(5px);
}
.carousel.left {
  left: 40px;
}

.carousel.inactive {
  cursor: not-allowed;
  background-color: rgb(17 16 19 / 8%);
}

.inline.inactive {
  cursor: not-allowed !important;
}

.carousel-icon {
  color: white !important;
}

.carousel.right {
  left: unset !important;
  right: -24px;
}

.justify-center {
  display: flex !important;
  justify-content: center !important;
}

.magic-edit-dialog-content * {
  font-family: var(--tf-font) !important;
}

.magic-edit-dialog-content::-webkit-scrollbar {
  width: 2px;
}

.magic-edit-dialog-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.magic-edit-dialog-content::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.magic-edit-variant-grid {
  display: flex;
  align-items: center;
  min-width: 350px;
  padding: 12px;
  background-color: #111013;
  margin-top: -7px;
  gap: 8px;
  justify-content: space-between;
}

.magic-edit-action-toolbar {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #11101366;
  z-index: 999;
}

.magic-edit-rect {
  outline: 3px solid #111013;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.magic-edit-rect.selected-rect,
.magic-edit-rect:hover,
.magic-edit-rect:active {
  opacity: 1;
  background-color: #ffffff56 !important;
  background-image: linear-gradient(45deg, #11101322 25%, transparent 25%, transparent 75%, #11101322 75%, #11101322),
    linear-gradient(45deg, #11101322 25%, transparent 25%, transparent 75%, #11101322 75%, #11101322) !important;
  background-size: 20px 20px !important;
  background-position:
    0 0,
    10px 10px !important;
}

.checkered {
  background-color: #ffffff56 !important;
  background-image: linear-gradient(45deg, #11101322 25%, transparent 25%, transparent 75%, #11101322 75%, #11101322),
    linear-gradient(45deg, #11101322 25%, transparent 25%, transparent 75%, #11101322 75%, #11101322) !important;
  background-size: 20px 20px !important;
  background-position:
    0 0,
    10px 10px !important;
}

.no-cursor {
  cursor: unset !important;
}

.slider-reset {
  cursor: pointer;
  user-select: none;
  position: absolute;
  left: 49.2%;
  top: -3px;
  transform: translateX(-50%);
  z-index: 9999;
  opacity: 0.6;
}

.slider-value {
  cursor: pointer;
  user-select: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  top: calc(50% - 8px);
  width: 3.5em;
  height: 1.5em;
  overflow: hidden;
  contain: strict;
  text-align: center;
  font-size: 0.75em;
}

.ReactCrop__drag-handle::after {
  background-color: #ffffff !important;
  border: 2px solid #111013 !important;
  outline: none !important;
  width: 8px !important;
  height: 8px !important;
}

.ReactCrop__crop-selection {
  border: none !important;
  outline: 2.5px solid #111013;
  box-shadow: 0 0 0 9999em rgb(0 0 0 / 0%) !important;
  -webkit-box-shadow: 0 0 0 9999em rgb(0 0 0 / 0%) !important;
  /* background-color: #ffffff56 !important;
  background-image: linear-gradient(45deg, #1110134d 25%, transparent 25%, transparent 75%, #1110134d 75%, #1110134d), linear-gradient(45deg, #1110134d 25%, transparent 25%, transparent 75%, #1110134d 75%, #1110134d) !important;
  background-size: 20px 20px !important;
  background-position: 0 0, 10px 10px !important; */
}

.ReactCrop .ord-e,
.ReactCrop .ord-n,
.ReactCrop .ord-w,
.ReactCrop .ord-s {
  display: none !important;
}

.no-text-transform {
  text-transform: none !important;
}

.chip-root {
  border: 1px solid #f1f1f2 !important;
}

.chip-label-med {
  height: 100% !important;
  padding-left: 0px !important;
  background-color: #f1f1f2;
}

.chip-delete-icon {
  position: absolute;
  top: -8px;
  margin-right: 0px !important;
  right: -8px;
  width: 14px !important;
  color: #111013 !important;
  border-radius: 100px;
  height: 14px !important;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  z-index: 12;
  padding: 1.8px !important;
}

.chip-label-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 10px;
}

.no-bg {
  background-color: unset !important;
}

.asset-prompt {
  display: flex !important;
  align-self: center !important;
  width: 40px !important;
  height: 40px !important;
  margin-left: 12px;
}

.chip-label-image {
  aspect-ratio: 1/1;
  object-fit: contain;
}

.chip-label-text {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-form-control {
  max-height: 40px;
}

.asset-label {
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 12px;
  gap: 6px;
  color: #0a0a0a;
  font-weight: 400;
  cursor: pointer;
}

.popper-root::-webkit-scrollbar {
  width: 4px;
}

.popper-root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.popper-root::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.open-asset-menu-item-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.open-asset-menu-item-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.open-asset-menu-item-scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.image-options-toolbar {
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
  transform-origin: left bottom;
  position: absolute;
  z-index: 1;
  top: -30px;
  left: 50%;
  transform: translate(-50%);
  transition: 0.3s ease-in-out;
}

.image-options-toolbar::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid white;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 15px;
}

.asset-focused {
  box-shadow:
    1px 1px 2px 2px rgb(0 0 0 / 20%),
    -1px -1px 2px 2px rgb(0 0 0 / 20%);
}

.body2 {
  font-family: var(--tf-font) !important;
  color: var(--cortex-dark-header) !important;
}

.asset:hover {
  box-shadow:
    1px 1px 2px 2px rgb(0 0 0 / 20%),
    -1px -1px 2px 2px rgb(0 0 0 / 20%);
}

.img-contain {
  object-fit: contain !important;
}

.toast-message {
  font: normal normal 400 14px/21px var(--tf-font);
  color: var(--cortex-secondary);
  width: 384px;
  height: 68px;
  margin-bottom: 60px;
  padding: 16px 24px;
}

.default-image-svg-filter {
  filter: invert(0.15);
  width: 36px;
}

.asset-view-image {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain !important;
}

.asset-default-image {
  height: 100%;
  filter: invert(0.4);
  aspect-ratio: 1/1;
  background-size: 36px !important;
}

.svg-no-stroke path {
  stroke: none !important;
}

.asset-picker-view-no-assets {
  display: flex;
  flex-direction: column !important;
  width: 100%;
  height: 100%;
  min-height: 288px !important;
  overflow: hidden;
}

@media (min-width: 651px) {
  .ce-toolbar__actions ce-toolbar__actions--opened {
    right: unset !important;
  }

  .codex-editor--narrow .ce-block--focused {
    padding-right: unset !important;
  }

  .codex-editor--narrow .codex-editor__redactor {
    margin-right: unset !important;
  }

  .ce-toolbox {
    right: -142px !important; /* Popover width - the block action handlers widths*/
  }
}

/* ----- CANVAS ------ */
@media (max-width: 768px) {
  .bottom-tab:hover {
    background-color: unset !important;
  }
  .mobile-content-wrapper {
    padding: 16px;
  }

  .mobile-tabs-wrapper {
    display: block;
    width: 100%;
  }

  .output-area-wrapper {
    padding-top: 10px;
    padding-left: 4px;
  }

  .mobile-d-none {
    display: none !important;
  }

  .mobile-tabs-wrapper .mobile-canvas-tabs {
    /* display: block; */
    position: sticky;
    top: 0px;
  }

  .mobile-tabs-wrapper .generate-prompt-wrapper {
    display: flex;
    height: inherit;
  }

  .generate-prompt-box {
    padding: 9px 15px 16px 15px;
  }

  .generate-prompt-box .generate-type-text span {
    display: none;
  }

  .generate-prompt-box .generate-prompt-type {
    font-size: 16px !important;
  }

  .generate-prompt-box .d-none {
    display: none;
  }

  .variations-container {
    padding-left: unset;
    padding-right: unset;
  }

  /* compose area */
  .prompt {
    min-width: unset;
  }

  .generatedImagePrompt {
    width: unset;
  }

  .custom-scrollbar::-webkit-scrollbar,
  .variations-sidebar::-webkit-scrollbar,
  .compose-area-wrapper-v2::-webkit-scrollbar {
    width: 5px;
  }

  /* Projects page */
  .MuiGrid-container .MuiGrid-item .cards-outer-wrapper .css-yza0h8-MuiPaper-root-MuiCard-root {
    height: unset;
    width: 167px;
  }

  /* Projects page css */
  .upload-asset-tabs {
    position: sticky;
    top: 49px;
  }

  .mobile-tabs-wrapper .mobile-upload-asset-tabs {
    /* position: sticky; */
    top: 0px;
    /* z-index: 100; */
  }

  .mobile-upload-asset-status {
    margin-top: -3px;
    margin-left: 16px;
    height: 87vh;
  }

  .upload-asset-status-sidebar {
    position: static;
    box-shadow: unset;
    padding-top: 23px;
    padding-left: 7px;
  }

  .mobile-upload-asset-wrapper {
    box-shadow: unset;
  }

  .cards-outer-wrapper .mobile-upload-asset-image {
    width: 169.84px;
    min-height: 169.84px;
  }

  .cards-outer-wrapper {
    gap: 3px;
  }

  .unstyledTabs button.MuiTab-root {
    font-size: 13px;
  }

  /* projects */
  .asset-card {
    max-width: unset;
    min-height: unset !important;
  }

  .cdx-settings-button {
    width: 44px !important;
    height: 44px !important;
    margin-right: 8px !important;
  }

  .cdx-settings-button svg {
    width: 40px !important;
    height: 40px !important;
    box-shadow: rgba(201, 201, 204, 0.48) 0px 0px 0px 1px;
  }

  .cdx-settings-button--active svg {
    width: 40px !important;
    height: 40px !important;
    box-shadow: rgba(201, 201, 204, 0.48) 0px 0px 0px 1px;
  }

  .cdx-settings-button--active {
    width: 44px !important;
    height: 44px !important;
    margin-right: 8px !important;
  }
}

/* tablet + mobile */
@media (max-width: 1024px) {
  /* header */
  .share-text-btn {
    display: none;
  }

  .share-icon {
    display: block;
  }

  .cortex-btn.tabs-menu-btn {
    display: block;
  }

  header {
    padding: 0 16px;
  }

  /* Bottom bar */
  .bottombar-outer {
    flex-direction: column;
    height: fit-content;
    width: fit-content;
    background-color: var(--cortex-dark-header);
    position: relative;
    bottom: unset;
    left: unset;
    z-index: unset;
  }

  .bottombar-outer .bottom-tab {
    margin-bottom: 10px;
  }

  .bottombar-outer .bottom-tab input {
    background-color: var(--cortex-dark-header);
    color: var(--cortex-white);
    pointer-events: none;
  }

  .bottombar-outer .bottombar-buttons {
    background-color: var(--cortex-dark-header);
    padding: 12px 0px;
  }

  .bottombar-outer .add-tab svg {
    color: white;
  }

  .bottom-tab:hover {
    background-color: unset !important;
  }
}
@media (min-width: 600px) and (max-width: 860px) {
  .custom-output-area-wrapper {
    padding: 0 !important;
  }
}
/* Canvas Tablet Media Queries */
@media (min-width: 769px) and (max-width: 1024px) {
  .generate-prompt-wrapper {
    min-width: 370px;
    width: 50%;
  }

  .cortex-btn.tabs-menu-btn {
    display: block !important;
  }

  .variations-container {
    padding-left: unset;
    padding-right: 40px;
  }

  .generate-prompt-box {
    padding: 9px 15px 16px 15px;
  }

  .output-area-wrapper {
    padding: 10px 16px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .mobile-content-wrapper {
    padding: 16px;
  }
  .ce-toolbar__actions {
    top: -26px !important;
  }
}

/* Home Page */

.home-page-box {
  max-width: 959px;
  padding: unset;
  overflow: visible;
}

/* Gif card */
.home-gif-card {
  justify-content: center;
  align-items: center;
}

.home-chevron-right-arrow {
  margin-top: -4px;
  margin-right: -16px;
}

.home-chevron-right-arrow-mobile {
  margin-top: -4px;
  margin-right: -20px;
}

.white-box {
  width: 964px;
  height: 659px;
  background-color: white;
}

.center-content {
  width: 40px;
  margin: auto;
}

/** Show placeholder at the first paragraph if Editor is empty */
.main-text[data-placeholder]:empty::before {
  content: attr(data-placeholder) !important;
  font-family: var(--tf-generated-content-font) !important;
  font-size: 14px !important;
  font-weight: normal;
  color: #0a0a0a66;
  opacity: 0;
}

.codex-editor--empty .ce-block:first-child .main-text[data-placeholder]:empty::before {
  opacity: 1 !important;
}

.codex-editor--empty .ce-block:first-child .main-text[data-placeholder]:empty::after {
  opacity: 1 !important;
}

/* .codex-editor--empty .ce-block:first-child p[data-placeholder]:blank{
  opacity: 1 !important;
} */

.codex-editor--empty .ce-block:first-child .main-text[data-placeholder]:empty:focus:before,
.codex-editor--empty .ce-block:first-child .main-text[data-placeholder]:empty:focus:after,
.codex-editor--toolbox-opened .ce-block:first-child .main-text[data-placeholder]:empty:before {
  opacity: 0.8 !important;
}

.menu-item-height {
  height: 48px;
  z-index: 99999;
}

.menu-item-width {
  width: 150px !important;
}

.disable-button {
  opacity: 0.7;
}

.box-shadow {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16) !important;
}

.sidebar-box-shadow {
  box-shadow: 0px 0.930909px 3.72364px rgba(0, 0, 0, 0.16);
  position: relative;
}

.home-projects-card {
  box-shadow: unset !important;
  border: 1px solid #ecebed !important;
}

.tf-button {
  height: 32px;
  border-radius: 0px !important;
  padding-left: 4px !important;
  padding-right: 10px !important;
}

.tf-button:hover {
  background-color: #f1f1f2 !important;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-top-8 {
  margin-top: 8px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.w-16 {
  width: 16px !important;
}

.flex-around {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.no-decoration {
  text-decoration: none;
}

.font-secondary {
  color: var(--cortex-secondary);
}

.margin-top-20 {
  margin-top: 20px;
}

.div-zoom-slider {
  margin-left: 40px;
  width: 50%;
  display: flex;
  flex-direction: row;
}

.div-zoom-slider .percentage-zoom-slider {
  padding-left: 15px;
  padding-top: 5px;
  color: rgba(160, 159, 161, 1);
}

.asset-training-status {
  margin-top: 10px;
}
