@font-face {
  font-family: "SuisseIntl";
    font-style: normal;
  font-weight: 400;
  src: local("SuisseIntl"),
    url(./fonts/SuisseIntl/SuisseIntl-Regular.otf) format('opentype');
}

@font-face {
  font-family: "SuisseIntl";
  font-weight: 500;
  src: local("SuisseIntl"),
    url(./fonts/SuisseIntl/SuisseIntl-Medium.otf) format('opentype');
}

@font-face {
  font-family: "SuisseIntl";
  font-weight: 700;
  src: local("SuisseIntl"),
    url(./fonts/SuisseIntl/SuisseIntl-Bold.otf) format('opentype');
}

@font-face {
  font-family: "SuisseIntl";
  font-weight: 600;
  src: local("SuisseIntl"),
    url(./fonts/SuisseIntl/SuisseIntl-SemiBold.otf) format('opentype');
}



/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

*/
/* Editor JS comes bundled with default paragraph. We do not want to use it */
[data-item-name="paragraph"] {
  display: none;
}

[data-item-name="Table"] {
  display: none;
}

[data-tool="paragraph"] {
  display: none;
}

/** we do not use Editor JS delete, as it messes up eventing. We create our own */ 
[data-item-name="delete"] {
  display: none;
}

/* These are related to printing the canvas */
@media screen {
  #canvas-editor-title {
    visibility: hidden;
    display:none;
  }
  #canvas-editor-footer {
    visibility: hidden;
    display:none;
  }
}
@media print {
  body * {
    visibility: hidden;
  }
  #canvas-editor, #canvas-editor * {
    visibility: visible;
  }
  #canvas-editor {
    position: absolute;
    left: 0;
    right: 0;
  }
  #canvas-editor-title {
    visibility: visible;
  }
  div.prompt-container {
    page-break-inside: avoid;
  }
  #canvas-editor-footer {
    position: fixed;
    bottom: 0;
    text-align: center;
  }
  @page {
    size: A4;
    margin-top: 0cm;
    margin-bottom: 0cm;
  }
}
