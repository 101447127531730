.error-page-action-button {
    width: 100%;
    max-width: 472px;
    background-color: #F1F1F2 !important;
    padding: 9px 0;
    font-size: 14px;
    color: #111013;
    border: 1px solid #F1F1F2 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1;
    letter-spacing : -0.02em;
    cursor: pointer;
}
  
.error-page-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #ffffff;
    transform: translateY(25%);
}